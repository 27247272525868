/**actions required for user list api */

import { createAction, props } from '@ngrx/store';
import {
  dynamicObject,
  ErrorInAPI,
  paginationQueryParam,
  SortAndPaginationModal,
} from '@models/common.model';
import {
  TransportPermitActionId,
  TransportPermitDetail,
  TransportPermitListPaginationResponse,
} from '@models/transport-permit.model';

/** list permit action */
export const loadTransportPermit = createAction(
  '[API] Load Transport Permit',
  props<{
    search?: string | '';
    pagination: paginationQueryParam;
    searchFilterObj?: dynamicObject;
  }>()
);

export const loadTransportPermitSuccess = createAction(
  '[API] Load Transport Permit with Success',
  props<{ transportPermitList: TransportPermitListPaginationResponse }>()
);

export const loadTransportPermitError = createAction(
  '[API] Load Transport Permit with Error',
  props<{ error: ErrorInAPI }>()
);

/** create permit action */
export const addTransportPermit = createAction(
  '[API] Add TransportPermit',
  props<{ transportPermit: TransportPermitDetail }>()
);

export const addTransportPermitSuccess = createAction(
  '[API] Add Transport Permit Success',
  props<{ transportPermit: TransportPermitDetail }>()
);

export const addTransportPermitError = createAction(
  '[API] Add Transport Permit Error',
  props<{ error: ErrorInAPI }>()
);

/** get permit detail action */
export const getTransportPermit = createAction(
  '[API] Get TransportPermit Detail',
  props<TransportPermitActionId>()
);

export const getTransportPermitSuccess = createAction(
  '[API] Get TransportPermit Detail Success',
  props<{ transportPermit: TransportPermitDetail }>()
);

export const getTransportPermitError = createAction(
  '[API] Get TransportPermit Detail Error',
  props<{ error: ErrorInAPI }>()
);

/**update permit detail */
export const updateTransportPermit = createAction(
  '[API] Update TransportPermit Detail',
  props<{ id: string | number; transportPermit: TransportPermitDetail }>()
);

export const updateTransportPermitSuccess = createAction(
  '[API] Update TransportPermit Detail Success',
  props<{ transportPermit: TransportPermitDetail }>()
);

export const updateTransportPermitError = createAction(
  '[API] Update TransportPermit Detail Error',
  props<{ error: ErrorInAPI }>()
);

export const loadTransportPermitWithSearch = createAction(
  '[API] Load Transport Permit With Search',
  props<{
    search?: string;
    pagination: SortAndPaginationModal;
    searchFilterObj?: dynamicObject;
  }>()
);

export const loadTransportPermitWithSearchSuccess = createAction(
  '[API] Load Transport Permit With Search Success',
  props<{ transportPermitList: TransportPermitListPaginationResponse }>()
);

export const resetStateAfterOperation = createAction(
  '[API] Reset State After Operation'
);

export const transportPermitOperationMessage = createAction(
  '[API] TransportPermit Operation Message',
  props<{ message: string }>()
);

export const resetStateSuccess = createAction('[API] Reset State Success');

export const TransportPermitActions = {
  loadTransportPermit,
  loadTransportPermitSuccess,
  loadTransportPermitError,
  resetStateAfterOperation,
  resetStateSuccess,
  addTransportPermit,
  addTransportPermitSuccess,
  addTransportPermitError,
  getTransportPermit,
  getTransportPermitError,
  getTransportPermitSuccess,
  updateTransportPermit,
  updateTransportPermitError,
  updateTransportPermitSuccess,
  transportPermitOperationMessage,
  loadTransportPermitWithSearch,
  loadTransportPermitWithSearchSuccess,
};
