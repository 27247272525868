import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SortSearchAndPaginationModal } from '@models/common.model';

import { map, Observable } from 'rxjs';
import { TransportPermitsManagementAPIConstant } from 'src/app/constant/api.constants';
import { objectToQueryString } from './contract.service';
import {
  TransportPermitDetail,
  TransportPermitListAPIResponse,
  TransportPermitListPaginationResponse,
} from '@models/transport-permit.model';
import { environment } from '@environment/environment';

@Injectable({
  providedIn: 'root',
})
export class TransportPermitAPIService {
  constructor(private http: HttpClient) {}

  /** List **/
  getTransportPermitList(
    param: SortSearchAndPaginationModal
  ): Observable<TransportPermitListPaginationResponse> {
    const pageNumber =
      param.pagination && param.pagination.pageNumber
        ? param.pagination.pageNumber
        : 0;
    const pageSize =
      param.pagination && param.pagination.pageSize
        ? param.pagination.pageSize
        : 5;
    const sortField =
      param.pagination && param.pagination.sortField
        ? param.pagination.sortField
        : 'createdAt';
    const sortDir =
      param.pagination && param.pagination.sortDir
        ? param.pagination.sortDir
        : 'DESC';
    let queryParam: string = '';
    const querySearchString =
      (param.search && `&queryString=${param.search}`) || '';
    if (sortField) {
      queryParam = `?sortField=${sortField}&sortDir=${sortDir.toUpperCase()}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    } else {
      queryParam = `?sortDir=${sortDir}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    }
    if (param.searchFilterObj) {
      queryParam = `${queryParam}&${objectToQueryString(
        param.searchFilterObj
      )}`;
    }
    return this.http
      .get<TransportPermitListAPIResponse>(
        environment.apiBaseUrl +
          TransportPermitsManagementAPIConstant.ORDER +
          queryParam +
          querySearchString
      )
      .pipe(
        map((apiResponse: TransportPermitListAPIResponse) => {
          return {
            data: apiResponse.items,
            meta: {
              itemCount: apiResponse.totalCount,
              pageCount: apiResponse.totalPages,
              currentPage: apiResponse.pageNumber,
              pageSize: pageSize,
            },
          };
        })
      );
  }

  /** Add */
  addTransporPermit(
    param: TransportPermitDetail
  ): Observable<TransportPermitDetail> {
    return this.http.post<TransportPermitDetail>(
      environment.apiBaseUrl + TransportPermitsManagementAPIConstant.ORDER,
      param
    );
  }

  /** Get */
  getTransportPermitDetail(
    id: string | number
  ): Observable<TransportPermitDetail> {
    return this.http.get<TransportPermitDetail>(
      environment.apiBaseUrl +
        TransportPermitsManagementAPIConstant.ORDER +
        '/' +
        id
    );
  }

  /** Update */
  updateTransportPermitDetail(
    id: string | number,
    transportPermit: TransportPermitDetail
  ): Observable<TransportPermitDetail> {
    return this.http.patch<TransportPermitDetail>(
      environment.apiBaseUrl +
        TransportPermitsManagementAPIConstant.ORDER +
        '/' +
        id,
      transportPermit
    );
  }

  /** Get Duplicate Permits */
  getDuplicateTransportPermits(
    transportOrderNumber: string,
    siteId: string,
    permitDate: string
  ): Observable<TransportPermitListPaginationResponse> {
    let queryParam = `?&siteId=${siteId}&dateFrom=${permitDate}&dateTo=${permitDate}&transportOrderNumber=${transportOrderNumber}&status=VALID`;

    return this.http
      .get<TransportPermitListAPIResponse>(
        environment.apiBaseUrl +
          TransportPermitsManagementAPIConstant.ORDER +
          queryParam +
          ''
      )
      .pipe(
        map((apiResponse: TransportPermitListAPIResponse) => {
          return {
            data: apiResponse.items,
            meta: {
              itemCount: apiResponse.totalCount,
              pageCount: apiResponse.totalPages,
              currentPage: apiResponse.pageNumber,
              pageSize: apiResponse.pageNumber,
            },
          };
        })
      );
  }
}
