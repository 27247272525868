import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  IDropdown,
  IDropdownStringOnly,
  SortSearchAndPaginationModal,
} from '@models/common.model';
import {
  TransportOrderDetail,
  TransportOrderListAPIResponse,
  TransportOrderListPaginationResponse,
} from '@models/transport-order.model';
import { Observable, map } from 'rxjs';
import { TransportOrderManagementAPIConstant } from 'src/app/constant/api.constants';
import { objectToQueryString } from './contract.service';
import { environment } from '@environment/environment';

@Injectable({
  providedIn: 'root',
})
export class TransportOrderAPIService {
  constructor(private http: HttpClient) {}

  /** List **/
  getTransportOrderList(
    param: SortSearchAndPaginationModal
  ): Observable<TransportOrderListPaginationResponse> {
    const pageNumber =
      param.pagination && param.pagination.pageNumber
        ? param.pagination.pageNumber
        : 0;
    const pageSize =
      param.pagination && param.pagination.pageSize
        ? param.pagination.pageSize
        : 20;
    const sortField =
      param.pagination && param.pagination.sortField
        ? param.pagination.sortField
        : 'createdAt';
    const sortDir =
      param.pagination && param.pagination.sortDir
        ? param.pagination.sortDir
        : 'DESC';
    let queryParam: string = '';
    const querySearchString =
      (param.search && `&queryString=${param.search}`) || '';
    if (sortField) {
      queryParam = `?sortField=${sortField}&sortDir=${sortDir.toUpperCase()}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    } else {
      queryParam = `?sortDir=${sortDir}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    }
    if (param.searchFilterObj) {
      queryParam = `${queryParam}&${objectToQueryString(
        param.searchFilterObj
      )}`;
    }
    return this.http
      .get<TransportOrderListAPIResponse>(
        environment.apiBaseUrl +
          TransportOrderManagementAPIConstant.ORDER +
          queryParam +
          querySearchString
      )
      .pipe(
        map((apiResponse: TransportOrderListAPIResponse) => ({
          data: apiResponse.items,
          meta: {
            itemCount: apiResponse.totalCount,
            pageCount: apiResponse.totalPages,
            currentPage: apiResponse.pageNumber,
            pageSize: pageSize,
          },
        }))
      );
  }

  /** Add */
  addTransportOrder(
    param: TransportOrderDetail
  ): Observable<TransportOrderDetail> {
    return this.http.post<TransportOrderDetail>(
      environment.apiBaseUrl + TransportOrderManagementAPIConstant.ORDER,
      param
    );
  }

  /** Get */
  getTransportOrderDetail(
    id: string | number
  ): Observable<TransportOrderDetail> {
    return this.http.get<TransportOrderDetail>(
      environment.apiBaseUrl +
        TransportOrderManagementAPIConstant.ORDER +
        '/' +
        id
    );
  }

  /** Update */
  updateTransportOrderDetail(
    id: string | number,
    transportOrder: TransportOrderDetail
  ): Observable<TransportOrderDetail> {
    return this.http.patch<TransportOrderDetail>(
      environment.apiBaseUrl +
        TransportOrderManagementAPIConstant.ORDER +
        '/' +
        id,
      transportOrder
    );
  }

  getTransportOrderDropdownList(): Observable<Partial<IDropdown>[]> {
    return this.http
      .get<TransportOrderListAPIResponse>(
        environment.apiBaseUrl +
          TransportOrderManagementAPIConstant.ORDER +
          `?pageSize=-1`
      )
      .pipe(
        map((apiResponse: TransportOrderListAPIResponse) => {
          let transportOrders: TransportOrderDetail[] = apiResponse.items;
          let dropDownValues: IDropdown[] = [];

          transportOrders.forEach((order) => {
            if (
              order.transportOrderNumber !== '' &&
              !dropDownValues.find(
                (value) => value.name === order.transportOrderNumber
              )
            ) {
              dropDownValues.push({
                id: order.id,
                name: order.transportOrderNumber,
              });
            }
          });
          dropDownValues.sort((a, b) => a.name.localeCompare(b.name));
          return dropDownValues;
        })
      );
  }
}
